<script lang="ts">
import { useUserStore } from '~/store/index'

export default {
    name: 'Connexion',
    setup() {
        const runtimeConfig = useRuntimeConfig()
        const access_token = useCookie<string>('access_token', { domain: runtimeConfig.public.accessTokenDomain })
        return {
            access_token,
        }
    },
    data: () => ({
        email: null,
        password: null,
        visible: false,
        loading: false,
    }),
    emits : ['launchRegister'],
    methods: {
        async login() {
            try {
                this.loading = true
                const res = await this.$api.setRequest({
                    url: 'login',
                    method: 'POST',
                    body: { email: this.email, password: this.password },
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                }, true)
                this.access_token = res.access_token
                useUserStore().fetchUser({ value: this.access_token })
                this.$router.push('/')
            }
            catch (error) {
                this.loading = false
                if (error.status === 429) {
                    this.$showToaster({ title: 'Échec de la connexion', text: `Trop de tentatives de connexion ont échoué.<br/> <b>Veuillez réessayer plus tard</b>`, color: 'error', icon: 'mdi-alert-circle', timer: -1 })
                } else {
                    this.$showToaster({ title: 'Échec de la connexion', text: `La connexion a échoué. <br/> Veuillez vérifier votre identifiant ou mot de passe.`, color: 'error', icon: 'mdi-alert-circle', timer: -1 })
                }
            }
        },
    },
}
</script>

<template>
    <div class="pa-0">
        <client-only>
            <v-form
                validate-on="submit lazy"
                @submit.prevent="login"
            >
                <label class="text-subtitle-1" for="email">
                    E-mail             
                    <span v-html="$config.public.form_label_required_text || '*'" />
                </label>
                <div class="text-caption text-medium-emphasis pb-1">
                    Format attendu : nom@domaine.fr
                </div>
                <v-text-field
                    id="email"
                    v-model="email"
                    density="compact"
                    variant="outlined"
                />

                <label class="text-subtitle-1" for="password">
                    Mot de passe
                    <span v-html="$config.public.form_label_required_text || '*'" />
                </label>

                <v-row>
                    <v-col>
                        <v-text-field
                            id="password"
                            v-model="password"
                            :type="visible ? 'text' : 'password'"
                            density="compact"
                            hide-details
                            variant="outlined"
                        />
                    </v-col>
                    <v-col cols="2" class="px-0">
                        <v-btn
                            color="primary"
                            class="my-1"
                            @click="visible = !visible" 
                            aria-label="Afficher ou cacher le mot de passe"
                        >
                        <v-icon :icon="visible ? 'mdi-eye-off' : 'mdi-eye'"></v-icon>
                    </v-btn>
                    </v-col>
                </v-row>

                <v-btn
                    variant="text"
                    class="text-caption text-primary pl-1"
                    @click="$router.push('/mot-de-passe-oublie')"
                >
                    Mot de passe oublié ?
                </v-btn>

                <v-btn
                    block
                    class="my-4"
                    color="primary"
                    size="large"
                    type="submit"
                    :loading="loading"
                >
                    Se connecter
                </v-btn>
            </v-form>
            <!-- <div class="d-flex">
                <v-divider class="mx-4" />
                <div class="position-relative" style="bottom : 5px;">
                    Autre moyen de connexion
                </div>
                <v-divider class="mx-4" />
            </div> -->
            <!-- <v-btn
                block
                variant="outlined"
                class="my-4"
                color="primary"
                size="large"
                @click="$showToaster({ title: 'À faire', color: 'warning' })"
            >
                Se connecter avec Géoplateforme17
            </v-btn> -->

            <v-card-title class="pa-0">
                Vous n'avez pas de compte ?
            </v-card-title>
            <v-btn
                block
                variant="outlined"
                class="my-4"
                color="primary"
                size="large"
                @click="$emit('launchRegister')"
            >
                Créer un compte
            </v-btn>
        </client-only>
    </div>
</template>
